<template>
    <b-modal id="tipoInternacionModal" size="lg" hide-footer>
      <template #modal-header>
        <h5 class="modal-title"><strong>Tipo de Internación</strong></h5>
      </template>
      <div class="modal-body pb-0 pr-0">
        <div class="row" style="margin-bottom: 2rem;">
          <div
            class="col-4 mb-2"
            v-for="(item, index) in tipoInternacion"
            :key="item.id"
          >
            <button
              class="btn btn-block"
              :class="[
                selectedOption === item.id ? 'btn-success' : 'btn-info',
                index % 3 === 1 ? 'bg-abierto' : ''
              ]"
              @click="selectOption(item.id)"
              style="width: 100%; white-space: normal; word-wrap: break-word;"
            >
              {{ item.nombre }}
            </button>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-12">
            <div class="row">
              <div class="col-sm-5 pr-0">
                <label for="">Los medicamentos se proporcionarán de farmacia</label>
              </div>
              <div class="col-sm-1 pl-1">
                <button class="btn btn-block text-center stiloBoton"
                :class="selectMedicamento ? 'btn-success' : 'btn-secondary'"
                @click="selectMedicamento = true">
                  Sí
                </button>
              </div>
              <div class="col-sm-1 pl-0">
                <button class="btn btn-block text-center stiloBoton"
                :class="!selectMedicamento ? 'btn-success' : 'btn-secondary'"
                @click="selectMedicamento = false"
                >
                  No
                </button>
              </div>
              <!-- <div class="col-sm-4 ml-5">
                <button class="btn btn-secondary mx-2">
                  Cancelar
                </button>
                <button
                  class="btn mx-2"
                  style="background: #f8cb9e; color: white"
                  @click="confirmSelection"
                >
                  Confirmar
                </button>
              </div> -->
            </div>
          </div>
        </div>
      </div>
    </b-modal>
  </template>

<script>
export default {
  name: 'theModalTipoInternacion',
  props: {
    tipoInternacion: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      selectedOption: 'tratamiento',
      selectMedicamento: true,
    };
  },
  methods: {
    selectOption(option) {
      this.selectedOption = option;
      if (this.selectedOption) {
        const idToPass = btoa(this.selectedOption);
        if (idToPass) {
          this.$router.push({ name: 'internacion-create', query: { id: idToPass } });
        }
      }
    },
    confirmSelection() {
      if (this.selectedOption) {
        const idToPass = this.selectedOption;
        if (idToPass) {
          this.$router.push({ name: 'internacion-create', query: { id: idToPass } });
        }
      } else {
        // eslint-disable-next-line no-alert
        alert('Por favor selecciona un tipo de internación antes de confirmar.');
      }
    },
  },
};
</script>

  <style scoped>
  .stiloBoton {
    width: 20px;
    height: 25px;
    font-size: 11px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .btn-blue {
    background: #252850 !important;
    color: white;
  }
  .bg-abierto {
    background: #f8cb9e;
    border-color: #f0ad4e; /* Asegúrate de que el borde también cambie */

  }
  </style>
