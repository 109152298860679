<template>
    <b-modal
      id="modalEstadoPrintInternacion"
      hide-footer
      header-class="d-block bg-gray-200"
      >
    <template #modal-header>
            <div class="row">
                <div class="col">
                  <b> Paciente: </b> {{ itemSelected.paciente }}
              </div>
              <div class="col-sm-3  text-right">
                <b> Int N°: </b> {{ itemSelected.numero }}
            </div>
            </div>
            <div  v-if="itemSelected.seguro != null" class="row">
                <div class="col">
                    <b>Seguro:</b> {{ itemSelected.seguro}}
                </div>
            </div>
    </template>

    <div class="row text-center">
        <div class="col-sm-3 ">
        </div>
        <div class="col-sm-8 ">

            <div class="row">
                <div class="col-sm-7 mb-2">
                  <button
                  class="btn btn-block btn-warning"
                  @click="imprimirPdfEstadoCuenta()"
                  >
                      Estado de Cuenta
                  </button>
                </div>
                <div class="col-sm-3 pl-0 d-flex align-items-center">
                  <button
                    class="btn px-2 py-1 btn-secondary"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      class="bi bi-printer"
                      viewBox="0 0 16 16"
                    >
                      <path d="M2.5 8a.5.5 0 1 0 0-1 .5.5 0 0 0 0 1z"/>
                      <path
                        d="M5 1a2 2 0 0 0-2 2v2H2a2 2 0 0 0-2 2v3a2 2 0 0 0 2 2h1v1a2
                        2 0 0 0 2 2h6a2 2 0 0 0 2-2v-1h1a2 2 0 0 0 2-2V7a2 2 0 0 0-2-2h-1V3a2
                        2 0 0 0-2-2H5zM4 3a1 1 0 0 1 1-1h6a1 1 0 0 1 1 1v2H4V3zm1 5a2 2 0 0 0-2
                        2v1H2a1 1 0 0 1-1-1V7a1 1 0 0 1 1-1h12a1 1 0 0 1 1 1v3a1 1 0 0 1-1 1h-1v-1a2
                        2 0 0 0-2-2H5zm7 2v3a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1v-3a1 1 0 0 1 1-1h6a1
                        1 0 0 1 1 1z"
                      />
                    </svg>
                  </button>
                </div>
              </div>
        </div>

      </div>
    </b-modal>
  </template>

<script>
import axios from '@/modules/common/axiosERP';
import { util } from '@/plugins/util';

export default {

  name: 'ModalEstadoPrintInternacion',
  props: {
    itemSelected: {
      type: Object,
    },
  },
  methods: {
    async imprimirPdfEstadoCuenta() {
      const TRATAMIENTO_ID = this.itemSelected.id;
      try {
        const RESPONSE = await axios.get(`/clinic/tratamientos/pre_liquidacion/print/${TRATAMIENTO_ID}`, {
          responseType: 'blob',
        });
        console.log('error', RESPONSE);
        const BLOB = new Blob([RESPONSE.data], { type: 'application/pdf' });
        const DOWNLOAD_URL = URL.createObjectURL(BLOB);
        window.open(DOWNLOAD_URL, '_blank');
      } catch (error) {
        util.showNotify(error.response.data.message, 'error');
      }
    },
  },
};
</script>
<style scoped>
.btn-blue{
    background: #252850!important;
    color: white;
}
</style>
